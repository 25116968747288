import React from "react"
import { Container } from "reactstrap"
import { FiInstagram, FiGithub, FiLinkedin } from "react-icons/fi"
import {
  AiOutlineWhatsApp,
  AiOutlineCopyrightCircle,
  AiFillHeart,
} from "react-icons/ai"
const social = [
  {
    id: 0,
    name: "Instagram",
    link: "https://www.instagram.com/ashadnasim/",
    icon: <FiInstagram />,
  },
  {
    id: 1,
    name: "Github",
    link: "https://github.com/ashadnasim52",
    icon: <FiGithub />,
  },

  {
    id: 3,
    name: "linkedin",
    link: "https://www.linkedin.com/in/ashad-nasim-83647a163/",
    icon: <FiLinkedin />,
  },

  {
    id: 5,
    name: "whatsapp",
    link: `https://wa.me/+918541866519?text=${encodeURI(
      "Hi Ashad, just came from your website"
    )}`,
    icon: <AiOutlineWhatsApp />,
  },
]

const Footer = () => {
  return (
    <footer>
      <Container className="footer" fluid>
        <div className="text-center cont">
          <p className="title">Ashad Nasim</p>
          <p>
            <AiOutlineCopyrightCircle /> All rights are reserved{" "}
            {new Date().getFullYear()}
          </p>
          <p>
            Made with <AiFillHeart className="font-weight-bolder text-danger" />{" "}
            by Ashad
          </p>
          <div className="links">
            {social.map(({ id, name, link, icon }) => (
              <a
                key={id}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="links"
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
